import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import 'dayjs/locale/en-gb'

// Import locales http://www.lingoes.net/en/translator/langcode.htm
import enGB from './locales/en-GB.json'
import esES from './locales/es-ES.json'

// Formater function
import { formater } from './formater'

// Events
// Must be before initialization to work
i18n.on('languageChanged', lng => {
  switch (lng.toLowerCase()) {
    case 'es-es':
      dayjs.locale('es')
      break

    default:
      dayjs.locale(lng.toLowerCase())
      break
  }
})

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-GB': enGB,
      es: esES,
    },
    fallbackLng: 'en-GB',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: formater,
    },
  })

export default i18n
