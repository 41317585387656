import { FormatFunction } from 'i18next'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import 'dayjs/locale/es'
import 'dayjs/locale/en-gb'

dayjs.extend(localizedFormat)

export const formater: FormatFunction = (value, rawFormat = '', lng) => {
  const [format, ...additionalFormatProperties] = rawFormat.split('|').map(v => v.trim())

  // Format Date instances following a format https://day.js.org/docs/en/display/format
  if (value instanceof Date) return dayjs(value).format(format)

  switch (format) {
    // Format strings replacing white spaces to underscore
    case 'snakecase':
      return value.replace(/ /g, '_').toLowerCase()

    // Format currency amounts replacing white spaces to underscore
    case 'currency':
      return Intl.NumberFormat(lng, {
        style: 'currency',
        currency: additionalFormatProperties[0],
      }).format(Number(value))

    default:
      return value
  }
}
