export const ROUTES_PATH = {
  HOME: '/',
  TRACEABILITY: '/traceability/:truId',
  PRODUCT: '/product/:productId',
  NOT_FOUND: '/404',
}

export const ROUTES = {
  /**
   * Home route
   */
  HOME: (): string => `/`,

  /**
   * Traceability route
   *
   * @param truId
   */
  TRACEABILITY: (truId: string): string => `/traceability/${truId}`,

  /**
   * Product's tru selection route
   *
   * @param productId
   */
  PRODUCT: (productId: string): string => `/product/${productId}`,

  /**
   * Not Found route
   */
  NOT_FOUND: ['/404', '/', '/*'] as const,
}
