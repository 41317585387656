import config from '@/config'
import { isProduction } from '@/utils/check-env'
import { noop } from '@/utils/noop'
import StackdriverErrorReporter from 'stackdriver-errors-js'

let errorReporter:
  | StackdriverErrorReporter
  | {
      start: () => void
      report: (message?: unknown, ...optionalParams: unknown[]) => void
      setUser: (user?: string) => void
    }

if (isProduction()) {
  errorReporter = new StackdriverErrorReporter()
  errorReporter.start({
    key: config.ERROR_REPORTING_KEY,
    projectId: config.GOOGLE_PROJECT_ID,
    service: config.MICROSERVICE_NAME,
    version: config.MICROSERVICE_VERSION,
  })
} else {
  // eslint-disable-next-line no-console
  errorReporter = { start: noop, report: console.error, setUser: noop }
}

export const ErrorReporterService = errorReporter
