import { toast } from 'react-hot-toast'
import { EventEmitter } from 'events'
import { Notification, NotificationType } from '@blockchain-traceability-sl/tailwind-components'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

const NotifyEventEmitter = new EventEmitter()
export enum NOTIFY_EVENT {
  ERROR = 'notify:error',
}

export interface NotifyBaseProps {
  title: string
  description: string
  actionText?: string
  onActionClick?: () => void
}

export interface NotifySuccessProps extends NotifyBaseProps {
  closeText?: string
  onClose?: () => void
}

export interface NotifyErrorProps extends NotifyBaseProps {
  actionText: string
}

interface UseNotifyInstance {
  success: (content: NotifySuccessProps) => string
  error: (content: NotifyErrorProps) => void
  onError: (handler: (props: NotifyErrorProps) => void) => void
}

export const useNotify = (): UseNotifyInstance => {
  const { t } = useTranslation('nsNotification')
  const onError = useMemo(
    () => (handler: (props: NotifyErrorProps) => void) =>
      NotifyEventEmitter.on(NOTIFY_EVENT.ERROR, handler),
    []
  )

  return {
    /**
     * Trigger a success notification
     *
     * @param content
     */
    success: (content: NotifySuccessProps): string => {
      return toast.custom(({ id, visible }) => (
        <Notification
          show={visible}
          type={NotificationType.SUCCESS}
          closeText={t('close')}
          {...content}
          onClose={() => {
            content.onClose && content.onClose()
            toast.dismiss(id)
          }}
          onActionClick={() => {
            content.onActionClick && content.onActionClick()
            toast.dismiss(id)
          }}
        />
      ))
    },

    /**
     * Trigger an error notification
     *
     * @param content
     */
    error: (content: NotifyErrorProps) => {
      NotifyEventEmitter.emit(NOTIFY_EVENT.ERROR, content)
    },

    /**
     * Listener an error notification
     */
    onError,
  }
}
