import { StrictMode, Suspense, lazy } from 'react'
import { render } from 'react-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { ROUTES_PATH } from './routes'
import './i18n'
import './index.css'
import { NotificationContainer } from './components/NotificationContainer'
import { FeatureFlagsProvider } from './utils/flags'

import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { ErrorBoundary } from './features/error-reporter'

const handleLazyImportError = () => {
  window.location.reload()
  return { default: () => null as unknown as JSX.Element }
}

const NotFound = lazy(() =>
  import('./pages/NotFound')
    .then(module => ({ default: module.NotFound }))
    .catch(handleLazyImportError)
)
const Home = lazy(() =>
  import('./pages/Home').then(module => ({ default: module.Home })).catch(handleLazyImportError)
)
const Traceability = lazy(() =>
  import('./pages/Traceability')
    .then(module => ({ default: module.Traceability }))
    .catch(handleLazyImportError)
)
const Product = lazy(() =>
  import('./pages/Product')
    .then(module => ({ default: module.Product }))
    .catch(handleLazyImportError)
)

const queryClient = new QueryClient()

render(
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <FeatureFlagsProvider>
          <Router>
            <Suspense fallback={null}>
              <Switch>
                <Route path={ROUTES_PATH.HOME} exact>
                  <Home />
                </Route>
                <Route path={ROUTES_PATH.PRODUCT}>
                  <Product />
                </Route>
                <Route path={ROUTES_PATH.TRACEABILITY}>
                  <Traceability />
                </Route>
                <Route path={ROUTES_PATH.NOT_FOUND}>
                  <NotFound />
                </Route>
                <Route path={'/*'}>
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
          </Router>
          <NotificationContainer />
        </FeatureFlagsProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
