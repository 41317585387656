import { NotFound } from '@/pages/NotFound'
import { Component, ReactNode } from 'react'
import { ErrorReporterService } from '../service'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  public componentDidCatch(error: Error): void {
    ErrorReporterService.report(error)
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return <NotFound />
    }

    return this.props.children
  }
}
