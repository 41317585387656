import { Alert, AlertType } from '@blockchain-traceability-sl/tailwind-components'
import { memo, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { NotifyErrorProps, useNotify } from '../hooks/use-notify'
import { useToggle } from '../hooks/use-toggle'

export const NotificationContainer = memo(() => {
  const [show, toggle] = useToggle(false)
  const [currentNotifyProps, setCurrentNotifyProps] = useState<NotifyErrorProps>()
  const { onError } = useNotify()

  useEffect(() => {
    onError(alert => {
      setCurrentNotifyProps(alert)
      toggle()
    })
  }, [onError, toggle])

  return (
    <>
      <Toaster position='top-right' />
      {currentNotifyProps && (
        <Alert
          title={currentNotifyProps.title}
          description={currentNotifyProps.description}
          confirmText={currentNotifyProps.actionText}
          type={AlertType.ERROR}
          show={show}
          onConfirm={() => {
            toggle()
            currentNotifyProps.onActionClick && currentNotifyProps.onActionClick()
            setCurrentNotifyProps(undefined)
          }}
        />
      )}
    </>
  )
})
