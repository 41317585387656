import { ReactNode, useRef } from 'react'
import { createClient } from 'configcat-js'
import Config from '@/config'
import { FeatureFlagsContext } from './context'
import { IFeatureFlagsClient } from './interfaces'
import { NoopFeatureFlagsClient } from './NoopFeatureFlagsClient'

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const clientRef = useRef<IFeatureFlagsClient>()

  if (!clientRef.current) {
    clientRef.current = Config.CONFIG_CAT_SDK_KEY
      ? createClient(Config.CONFIG_CAT_SDK_KEY)
      : new NoopFeatureFlagsClient()
  }

  return (
    <FeatureFlagsContext.Provider value={clientRef.current}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
